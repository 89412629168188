export function useAppUrls() {
    const runtimeConfig = useRuntimeConfig()
    const { locale } = useI18n()

    const signinUrl = computed(
        () =>
            runtimeConfig.public.EFIRMA_APP_URL +
            '/signin' +
            `?locale=${locale.value}`,
    )
    const registerUrl = computed(
        () =>
            runtimeConfig.public.EFIRMA_APP_URL +
            '/register' +
            `?locale=${locale.value}`,
    )
    const subscriptionUrl = (plan: 'free' | 'standard' | 'pro') =>
        runtimeConfig.public.EFIRMA_APP_URL +
        '/register' +
        `?locale=${locale.value}` +
        `&plan=${plan}`

    return {
        signinUrl,
        registerUrl,
        subscriptionUrl,
    }
}
