<template>
    <div class="flex h-full flex-col">
        <AppHeader />

        <main>
            <SectionHero />

            <SectionSharedSpace />

            <SectionPrimaryFeatures />

            <SectionPlans />

            <SectionAboutUs />

            <SectionContactUs />
        </main>

        <AppFooter />
    </div>
</template>
