<template>
    <section
        id="plans"
        class="relative overflow-hidden"
        :aria-label="`${t('title.our')}${t('title.plans')}`"
    >
        <!-- Gradient -->
        <div
            class="pointer-events-none absolute inset-0 bg-gradient-to-b from-white from-10% to-blue-600/10 to-100%"
        />

        <BaseContainer class="jusitfy-center relative flex flex-col gap-10">
            <h2 class="text-center text-4xl font-bold leading-10">
                <span>{{ t('title.our') }}</span>

                <div class="relative inline-block">
                    <img
                        src="/drawings/our-plans-circle.svg"
                        alt="our plans drawing"
                        :class="[
                            'absolute scale-150 opacity-30',
                            locale === 'bg' ? '-right-4' : '-right-2 top-2',
                        ]"
                    />

                    <span class="relative">
                        {{ t('title.plans') }}
                    </span>
                </div>
            </h2>

            <p class="pb-10 pt-4 text-center text-xl leading-7">
                {{ t('subheader') }}
            </p>

            <!-- Annually / Monthly -->
            <div class="flex flex-col space-y-2 justify-self-center">
                <p
                    class="text-center font-light normal-case"
                    v-html="getBoldedHTML(t('two-months-free'))"
                />
                <div
                    class="rounded-4xl flex items-center justify-center self-center border border-slate-200 bg-white p-1"
                >
                    <button
                        class="rounded-full px-7 py-2 text-sm font-bold capitalize"
                        :class="[
                            isAnnual
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-600',
                        ]"
                        @click="isAnnual = true"
                    >
                        {{ t('annually') }}
                    </button>

                    <button
                        class="rounded-full px-7 py-2 text-sm font-bold capitalize"
                        :class="[
                            isAnnual
                                ? 'text-gray-600'
                                : 'bg-blue-600 text-white',
                        ]"
                        @click="isAnnual = false"
                    >
                        {{ t('monthly') }}
                    </button>
                </div>
            </div>

            <!-- Plans -->
            <div
                class="grid items-start gap-x-3 gap-y-6 md:grid-cols-3 lg:gap-x-4"
            >
                <div
                    v-for="plan in plans"
                    :key="plan.name"
                    class="row-span-4 grid grid-cols-1 grid-rows-subgrid gap-0 rounded-3xl border-2 border-blue-600/20 bg-white/30 p-8 md:p-6 lg:p-10"
                >
                    <div class="flex flex-col">
                        <div
                            class="flex flex-wrap items-center gap-2 font-bold"
                        >
                            <span
                                class="text-3xl font-bold md:text-xl lg:text-3xl"
                            >
                                {{ plan.name }}
                            </span>
                            <div
                                v-if="plan.comingSoon"
                                class="inline-flex items-center justify-center rounded-md bg-gray-600 px-3 py-1 text-center text-xs font-medium leading-4 text-white shadow"
                            >
                                {{ t('comingSoon') }}
                            </div>
                        </div>
                        <span
                            class="mt-2 text-lg leading-7 md:text-sm lg:text-lg"
                        >
                            {{ plan.description }}
                        </span>
                    </div>

                    <div
                        v-if="plan.price"
                        class="my-5 flex flex-col items-baseline"
                        :class="plan.comingSoon && 'opacity-40'"
                    >
                        <span
                            class="text-4xl font-bold md:text-2xl lg:text-4xl"
                        >
                            {{ formatCurrency(plan.price, 'BGN', locale) }}
                        </span>
                        <span
                            class="md:text-md whitespace-nowrap text-xl lg:text-lg"
                        >
                            /{{ isAnnual ? t('annually') : t('monthly') }}
                        </span>
                    </div>
                    <span v-else />

                    <BaseButton
                        :to="plan.ctaLink"
                        :disabled="plan.comingSoon"
                        class="w-full"
                        variant="solid"
                        color="blue"
                    >
                        {{ plan.cta }}
                    </BaseButton>

                    <div class="mt-10 flex flex-col gap-4">
                        <p
                            v-for="feature in plan.features"
                            :key="feature"
                            class="flex gap-2"
                            :class="plan.comingSoon && 'opacity-40'"
                        >
                            <svg
                                width="24"
                                height="25"
                                class="inline-block flex-shrink-0"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 2.2002C6.48 2.2002 2 6.6802 2 12.2002C2 17.7202 6.48 22.2002 12 22.2002C17.52 22.2002 22 17.7202 22 12.2002C22 6.6802 17.52 2.2002 12 2.2002ZM12 20.2002C7.59 20.2002 4 16.6102 4 12.2002C4 7.7902 7.59 4.2002 12 4.2002C16.41 4.2002 20 7.7902 20 12.2002C20 16.6102 16.41 20.2002 12 20.2002ZM16.59 7.7802L10 14.3702L7.41 11.7902L6 13.2002L10 17.2002L18 9.2002L16.59 7.7802Z"
                                    fill="#475569"
                                />
                            </svg>

                            <span v-html="feature" />
                        </p>
                    </div>
                </div>
            </div>
        </BaseContainer>
    </section>
</template>

<script setup lang="ts">
import { useAppUrls } from '~/composables/useAppUrls'

const { subscriptionUrl } = useAppUrls()
const { t, locale, messages } = useI18n()

const starterFeatureCount = getFeatureCount('free')
const smallBusinessFeatureCount = getFeatureCount('standard')
const enterpriseFeatureCount = getFeatureCount('pro')

const isAnnual = ref(false)

const plans = computed(() => [
    {
        name: t('plans.free.name'),
        price: 0,
        description: t('plans.free.description'),
        features: Array.from({ length: starterFeatureCount }).map((_, n) =>
            getBoldedHTML(t(`plans.free.features[${n}]`)),
        ),
        cta: t('plans.free.cta'),
        ctaLink: subscriptionUrl('free'),
    },
    {
        name: t('plans.standard.name'),
        price: isAnnual.value ? 10000 : 1000,
        description: t('plans.standard.description'),
        features: Array.from({ length: smallBusinessFeatureCount }).map(
            (_, n) => getBoldedHTML(t(`plans.standard.features[${n}]`)),
        ),
        cta: t('plans.standard.cta'),
        ctaLink: subscriptionUrl('standard'),
    },
    {
        comingSoon: true,
        name: t('plans.pro.name'),
        price: isAnnual.value ? 25000 : 2500,
        description: t('plans.pro.description'),
        features: Array.from({ length: enterpriseFeatureCount }).map((_, n) =>
            getBoldedHTML(t(`plans.pro.features[${n}]`)),
        ),
        cta: t('plans.pro.cta'),
        ctaLink: subscriptionUrl('pro'),
    },
])

function getBoldedHTML(text: string) {
    return text.replace(/~(.+?)~/g, '<b>$1</b>')
}

function getFeatureCount(feature: string) {
    // @ts-ignore silence i18n
    return messages.value.en.plans[feature].features.length as number
}
</script>

<i18n lang="yaml">
en:
    title:
        our: 'Our '
        plans: 'plans'
    subheader: 'If you are wondering which plan would be best for you, do not hesitate to contact us!'
    annually: 'annually'
    monthly: 'monthly'
    two-months-free: '~2 months free~ with annual plan'
    comingSoon: 'Coming soon'
    plans:
        free:
            name: 'Free'
            description: 'Suitable for anyone who is self-employed and just starting out'
            features:
                - Export invoices as PDF
                - BG/EN document languages
                - Multiple currencies
                - Data export
                - 10 revenue documents per month
                - 10 expense documents per month
                - 10 contacts
                - 10 items
            cta: 'Start for free'
        standard:
            name: 'Standard'
            description: 'Perfect for small or medium-sized businesses'
            features:
                - Export invoices as PDF
                - BG/EN document languages
                - Multiple currencies
                - Data export
                - Unlimited revenue documents
                - Unlimited expense documents
                - Unlimited contacts
                - Unlimited items
                - Send PDF invoices via email
                - Logo on invoices
                - Expense file attachments
            cta: 'Choose plan'
        pro:
            name: 'Pro'
            description: 'For established businesses needing advanced document automation'
            features:
                - Export invoices as PDF
                - BG/EN document languages
                - Multiple currencies
                - Data export
                - Unlimited revenue documents
                - Unlimited expense documents
                - Unlimited contacts
                - Unlimited items
                - Send PDF invoices via email
                - Logo on invoices
                - Expense file attachments
                - Automatic data filling from uploaded invoice
                - Business premises
                - Custom user roles
            cta: 'Choose plan'
bg:
    title:
        our: 'Нашите '
        plans: 'планове'
    subheader: 'Ако се чудите кой план би бил най-подходящ за вас, не се колебайте да се свържете с нас!'
    annually: 'годишно'
    monthly: 'месечно'
    two-months-free: '~2 месеца безплатно~ с годишен план'
    comingSoon: 'Очаквайте скоро'
    plans:
        free:
            name: 'Free'
            description: 'Подходящо за всеки, който е самостоятелно зает и тепърва започва'
            features:
                - Експорт на фактури в PDF
                - БГ/EN език на документите
                - Различни валути
                - Експорт на данни
                - 10 приходни документа на месец
                - 10 разходни документа на месец
                - 10 контакта
                - 10 артикула
            cta: 'Започни безплатно'
        standard:
            name: 'Standard'
            description: 'Перфектен за малък или среден бизнес'
            features:
                - Експорт на фактури в PDF
                - БГ/EN език на документите
                - Различни валути
                - Експорт на данни
                - Неограничен брой приходни документи
                - Неограничен брой разходни документи
                - Неограничен брой контакти
                - Неограничен брой артикули
                - Изпращане на PDF директно по имейл към клиента
                - Лого към фактурите
                - Прикачване на файлове към разходите
            cta: 'Избери план'
        pro:
            name: 'Pro'
            description: 'За утвърдени бизнеси с нужда от напреднала автоматизация на документи'
            features:
                - Експорт на фактури в PDF
                - БГ/EN език на документите
                - Различни валути
                - Експорт на данни
                - Неограничен брой приходни документи
                - Неограничен брой разходни документи
                - Неограничен брой контакти
                - Неограничен брой артикули
                - Изпращане на PDF директно по имейл към клиента
                - Лого към фактурите
                - Прикачване на файлове към разходите
                - Автоматично попълване на данни от качена фактура
                - Търговски обекти
                - Персонализирани роли
            cta: 'Избери план'
</i18n>
