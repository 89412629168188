export function formatCurrency(
    amount: number,
    currencyCode: string,
    locale: string,
): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
    })

    // Money amounts will always be in cents, so we divide by 100
    return formatter.format(amount / 100)
}
